import Vue from 'vue'
import Vuex from 'vuex'
import drawer from './modules/drawer'
import setting from './modules/setting'
import user from './modules/user'
import withdrawal from './modules/withdrawal'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  // ASCENDING ALPHABET : A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z
  modules: {
    drawer,
    setting,
    user,
    withdrawal,
  },
  strict: debug,
})

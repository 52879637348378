import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/Login',
      component: () => import('@/views/dashboard/Login'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },

        // Employee
        {
          name: 'Employee',
          path: 'employee',
          component: () => import('@/views/dashboard/pages/employee/Employee'),
          meta: {
            titleBar: 'Pegawai',
          },
        },
        {
          name: 'EmployeeAdd',
          path: 'employee/add',
          component: () => import('@/views/dashboard/pages/employee/EmployeeAdd'),
          meta: {
            titleBar: 'Add Employee',
          },
        },
        {
          name: 'EmployeeDetail',
          path: 'employee/detail/:id',
          component: () => import('@/views/dashboard/pages/employee/EmployeeDetail'),
          meta: {
            titleBar: 'Employee Detail',
          },
        },

        // Partner
        {
          name: 'Partner',
          path: 'partner',
          component: () => import('@/views/dashboard/pages/partner/Partner'),
          meta: {
            titleBar: 'Mitra',
          },
        },
        {
          name: 'PartnerDetail',
          path: 'partner/partnerdetail/:id',
          component: () => import('@/views/dashboard/pages/partner/PartnerDetail'),
          meta: {
            titleBar: 'Mitra Detail',
          },
        },
        {
          name: 'PartnersVerification',
          path: 'partner/partnersverification',
          component: () => import('@/views/dashboard/pages/partner/PartnersVerification'),
          props: true,
          meta: {
            titleBar: 'Verifikasi Mitra',
          },
        },
        {
          name: 'PartnerVerification',
          path: 'partner/partnerverification/:id',
          component: () => import('@/views/dashboard/pages/partner/PartnerVerification'),
          props: true,
          meta: {
            titleBar: 'Verifikasi Mitra',
          },
        },

        // Partner Company
        {
          name: 'PartnerCompany',
          path: 'partnercompany',
          component: () => import('@/views/dashboard/pages/partner_company/PartnerCompany'),
          meta: {
            titleBar: 'Perusahaan Mitra',
          },
        },
        {
          name: 'PartnerCompanyDetail',
          path: 'partnercompany/partnercompanydetail/:id',
          component: () => import('@/views/dashboard/pages/partner_company/PartnerCompanyDetail'),
          meta: {
            titleBar: 'Perusahaan Mitra Detail',
          },
        },
        {
          name: 'PartnerCompaniesVerification',
          path: 'partnercompany/partnercompaniesverification',
          component: () => import('@/views/dashboard/pages/partner_company/PartnerCompaniesVerification'),
          meta: {
            titleBar: 'Verifikasi Perusahaan Mitra',
          },
        },
        {
          name: 'PartnerCompanyVerification',
          path: 'partnercompany/partnercompanyverification/:id',
          component: () => import('@/views/dashboard/pages/partner_company/PartnerCompanyVerification'),
          meta: {
            titleBar: 'Verifikasi Perusahaan Mitra',
          },
        },

        // Partner Offline Order
        {
          name: 'PartnerOfflineOrder',
          path: 'partnerofflineorder',
          component: () => import('@/views/dashboard/pages/partner_offline_order/PartnerOfflineOrder'),
          meta: {
            titleBar: 'Penjualan Offline Partner',
          },
        },

        // Partner Vendor
        {
          name: 'PartnerVendor',
          path: 'partnervendor',
          component: () => import('@/views/dashboard/pages/partner_vendor/PartnerVendor'),
          meta: {
            titleBar: 'Partner Vendor',
          },
        },

        // Partner Company Fee
        {
          name: 'PartnerCompanyFee',
          path: 'partnercompanyfee',
          component: () => import('@/views/dashboard/pages/partner_company_fee/PartnerCompanyFee'),
          meta: {
            titleBar: 'Bagi Hasil Mitra',
          },
        },

        // Customer
        {
          name: 'Customer',
          path: 'customer',
          component: () => import('@/views/dashboard/pages/customer/Customer'),
          meta: {
            titleBar: 'Penyewa',
          },
        },
        {
          name: 'CustomerDetail',
          path: 'customer/customerdetail/:id',
          component: () => import('@/views/dashboard/pages/customer/CustomerDetail'),
          props: true,
          meta: {
            titleBar: 'Detail Penyewa',
          },
        },
        {
          name: 'CustomersVerification',
          path: 'customer/customersverification',
          component: () => import('@/views/dashboard/pages/customer/CustomersVerification'),
          props: true,
          meta: {
            titleBar: 'Verifikasi Penyewa',
          },
        },
        {
          name: 'CustomerVerification',
          path: 'customer/customerverification/:id',
          component: () => import('@/views/dashboard/pages/customer/CustomerVerification'),
          props: true,
          meta: {
            titleBar: 'Verifikasi Penyewa',
          },
        },

        // Customer Company
        {
          name: 'CustomerCompany',
          path: 'customercompany',
          component: () => import('@/views/dashboard/pages/customer_company/CustomerCompany'),
          meta: {
            titleBar: 'Perusahaan Penyewa',
          },
        },
        {
          name: 'CustomerCompanyDetail',
          path: 'customercompany/customercompanydetail/:id',
          component: () => import('@/views/dashboard/pages/customer_company/CustomerCompanyDetail'),
          meta: {
            titleBar: 'Perusahaan Penyewa Detail',
          },
        },
        {
          name: 'CustomerCompaniesVerification',
          path: 'customercompany/customercompaniesverification',
          component: () => import('@/views/dashboard/pages/customer_company/CustomerCompaniesVerification'),
          meta: {
            titleBar: 'Verifikasi Perusahaan Penyewa',
          },
        },
        {
          name: 'CustomerCompanyVerification',
          path: 'customercompany/customercompanyverification/:id',
          component: () => import('@/views/dashboard/pages/customer_company/CustomerCompanyVerification'),
          meta: {
            titleBar: 'Verifikasi Perusahaan Penyewa',
          },
        },

        // Wallet
        {
          name: 'Wallet',
          path: 'wallet',
          component: () => import('@/views/dashboard/pages/wallet/Wallet'),
          meta: {
            titleBar: 'Dompet',
          },
        },
        {
          name: 'WalletDetail',
          path: 'wallet/detail/:id',
          component: () => import('@/views/dashboard/pages/wallet/WalletDetail'),
          meta: {
            titleBar: 'Dompet Detail',
          },
        },

        // Wpoint
        {
          name: 'WalletPoint',
          path: 'walletpoint',
          component: () => import('@/views/dashboard/pages/wallet_point/WalletPoint'),
          meta: {
            titleBar: 'Poin Dompet',
          },
        },
        {
          name: 'WalletPointDetail',
          path: 'walletpoint/detail/:id',
          component: () => import('@/views/dashboard/pages/wallet_point/WalletPointDetail'),
          meta: {
            titleBar: 'Poin Dompet Detail',
          },
        },

        // Ticket
        {
          name: 'Ticket',
          path: 'ticket',
          component: () => import('@/views/dashboard/pages/ticket/Ticket'),
          meta: {
            titleBar: 'Tiket',
          },
        },
        {
          name: 'TicketDetail',
          path: 'ticket/detail/:id',
          component: () => import('@/views/dashboard/pages/ticket/TicketDetail'),
          meta: {
            titleBar: 'Tiket Detail',
          },
        },

        // Asset
        {
          name: 'Asset',
          path: 'asset',
          component: () => import('@/views/dashboard/pages/asset/Asset'),
          meta: {
            titleBar: 'Aset',
          },
        },
        {
          name: 'AssetDetail',
          path: 'assetdetail',
          component: () => import('@/views/dashboard/pages/asset/AssetDetail'),
          meta: {
            titleBar: 'Aset Detail',
          },
        },
        {
          name: 'AssetsVerification',
          path: 'assetsverification',
          component: () => import('@/views/dashboard/pages/asset/AssetsVerification'),
          meta: {
            titleBar: 'Verifikasi Aset',
          },
        },
        {
          name: 'AssetVerification',
          path: 'assetsverification/verify/:id',
          component: () => import('@/views/dashboard/pages/asset/AssetVerification'),
          meta: {
            titleBar: 'Verifikasi Aset',
          },
        },
        {
          name: 'AssetVerificationPhoto',
          path: 'assetphotoverification',
          component: () => import('@/views/dashboard/pages/asset/AssetVerificationPhoto'),
          meta: {
            titleBar: 'Verifikasi Foto Aset',
          },
        },

        // Invoice
        {
          name: 'Invoice',
          path: 'invoice',
          component: () => import('@/views/dashboard/pages/invoice/Invoice'),
          meta: {
            titleBar: 'Tagihan',
          },
        },

        // Order
        {
          name: 'Order',
          path: 'order',
          component: () => import('@/views/dashboard/pages/order/Order'),
          meta: {
            titleBar: 'Pesanan',
          },
        },
        {
          name: 'OrderDetail',
          path: 'order/detail/:id?',
          component: () => import('@/views/dashboard/pages/order/OrderDetail'),
          meta: {
            titleBar: 'Detail Pesanan',
          },
        },

        // Production
        {
          name: 'Production',
          path: 'production',
          component: () => import('@/views/dashboard/pages/production/Production'),
          meta: {
            titleBar: 'Produksi',
          },
        },
        {
          name: 'ProductionDetail',
          path: 'production/productiondetail/:id',
          component: () => import('@/views/dashboard/pages/production/ProductionDetail'),
          props: true,
          meta: {
            titleBar: 'Produksi Detail',
          },
        },

        // Withdrawal
        {
          name: 'Withdrawal',
          path: 'withdrawal',
          component: () => import('@/views/dashboard/pages/withdrawal/Withdrawal'),
          meta: {
            titleBar: 'Penarikan',
          },
        },
        {
          name: 'WithdrawalDetail',
          path: 'withdrawal/detail/:id',
          component: () => import('@/views/dashboard/pages/withdrawal/WithdrawalDetail'),
          meta: {
            titleBar: '',
          },
        },

        // Setting
        {
          name: 'Setting',
          path: 'setting',
          component: () => import('@/views/dashboard/pages/setting/Setting'),
          meta: {
            titleBar: 'Setting',
          },
        },

        // Management
        {
          name: 'Management',
          path: 'management',
          component: () => import('@/views/dashboard/pages/management/Management'),
          meta: {
            titleBar: 'Management',
          },
        },

        // Management Partner
        {
          name: 'ManagementPartner',
          path: 'managementpartner',
          component: () => import('@/views/dashboard/pages/management_partner/Management'),
          meta: {
            titleBar: 'Management Partner',
          },
        },

        // Bank Verification
        {
          name: 'BankVerification',
          path: 'bankverification',
          component: () => import('@/views/dashboard/pages/bank_verification/BankVerification'),
          meta: {
            titleBar: 'Bank Verification',
          },
        },

        // Notification
        {
          name: 'Notification',
          path: 'notification',
          component: () => import('@/views/dashboard/pages/notification/Notification'),
          meta: {
            titleBar: '',
          },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (localStorage.getItem('token') === null) {
      next({ name: 'Login' })
      return
    }

    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')
    axios.post('/g/checkAuth').then((res) => {
      if (res.data.status === 200) {
        next()
      }
    }).catch((e) => {
      localStorage.clear()
      next({ name: 'Login' })
    })
  } else {
    next()
  }
})

export default router

const state = {
    name: 'WITHDRAWAL',
    tableHeaders: [
        { text: 'Tipe User', value: 'rep_user_entity_long', sortable: true },
        { text: 'Nama User', value: 'rep_user_name', sortable: true },
        { text: 'Nominal Penarikan', value: 'nominal', sortable: true },
        { text: 'Status', value: 'status_string', sortable: true },
        { text: 'Tanggal Permintaan', value: 'created_time', sortable: true },
        { text: '', value: 'actions', sortable: false },
    ],
}

const mutations = {}

const getters = {}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}

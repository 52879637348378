const state = {
    menu_notif: {
        menu_employee: 0,
        menu_customer: 0,
        menu_customer_company: 0,
        menu_partner: 0,
        menu_partner_company: 0,
        menu_verify_bank: 0,
        menu_partner_offline_sales: 0,
        menu_partner_vendor: 0,
        menu_partner_share: 0,
        menu_wallet: 0,
        menu_wpoint: 0,
        menu_ticket: 0,
        menu_asset: 0,
        sub_menu_asset_verification: 0,
        menu_invoice: 0,
        menu_order: 0,
        menu_production: 0,
        menu_withdrawal: 0,
        menu_management: 0,
    },
}

const mutations = {
    SET_SIDEBAR_NOTIFICATION (state, payload) {
        state.menu_notif = payload
    },
}

const getters = {}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}

const state = {
    userinfo: 'userinfo',
}

const mutations = {
    SET_USER_DATA (state, payload) {
        state.userinfo = payload
    },
}

const getters = {}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
